define("discourse/plugins/discourse-authentication-validations/discourse/helpers/setup-user-field-validation", ["exports", "@ember/component/helper", "@ember/service"], function (_exports, _helper, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SetupUserFieldValidation extends _helper.default {
    static #_ = (() => dt7948.g(this.prototype, "userFieldValidations", [_service.inject]))();
    #userFieldValidations = (() => (dt7948.i(this, "userFieldValidations"), void 0))();
    compute(_ref) {
      let [object] = _ref;
      this.userFieldValidations.setValidation(object.field, object.value);
    }
  }
  _exports.default = SetupUserFieldValidation;
});