define("discourse/plugins/discourse-authentication-validations/discourse/services/user-field-validations", ["exports", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service"], function (_exports, _tracking, _object, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserFieldValidations extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "totalCustomValidationFields", [_tracking.tracked], function () {
      return 0;
    }))();
    #totalCustomValidationFields = (() => (dt7948.i(this, "totalCustomValidationFields"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "userFields", [_tracking.tracked]))();
    #userFields = (() => (dt7948.i(this, "userFields"), void 0))();
    currentCustomValidationFieldCount = 0;
    setValidation(userField, value) {
      this._bumpTotalCustomValidationFields();
      if (this.currentCustomValidationFieldCount === this.totalCustomValidationFields) {
        (0, _runloop.next)(() => this.crossCheckValidations(userField, value));
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "setValidation", [_object.action]))();
    crossCheckValidations(userField, value) {
      let shouldShow = userField.show_values.includes(value);
      if (value === null && userField.show_values.includes("null")) {
        shouldShow = true;
      }
      this._updateTargets(userField.target_user_field_ids, shouldShow);
    }
    static #_5 = (() => dt7948.n(this.prototype, "crossCheckValidations", [_object.action]))();
    _updateTargets(userFieldIds, shouldShow) {
      userFieldIds.forEach(id => {
        const userField = this.site.user_fields.find(field => field.id === id);
        const className = `user-field-${userField.name.toLowerCase().replace(/\s+/g, "-")}`;
        const userFieldElement = document.querySelector(`.${className}`);
        if (userFieldElement) {
          userFieldElement.style.display = shouldShow ? "" : "none";
        }
      });
    }
    _bumpTotalCustomValidationFields() {
      if (this.totalCustomValidationFields !== this.currentCustomValidationFieldCount) {
        this.currentCustomValidationFieldCount += 1;
      }
    }
  }
  _exports.default = UserFieldValidations;
});